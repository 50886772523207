/* eslint-disable no-nested-ternary */
import BreadCrumb from '@medical/components/Breadcrumb'
import { dateValidation, waitFor } from '@medical/libs'
import styles from '@medical/pages/staff/MonthlyAvailableShift/Style'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import Axios from 'axios'
import { DOWNLOAD_CSV_WORK_SCHEDULE_LIST } from '@medical/constant'

const WorkSchedulesListScene = ({
  i18n,
  workScheduleLists,
  clinicalDepartmentIds,
  clinicalDepartments,
  currentDate,
  onSortHandle,
  rowsPerPage,
  page,
  count,
  onPageChange,
  history,
  statusSearch,
  urlDownloadMonthlyWorkSchedules,
  socket,
  progress,
  setProgress,
  removeProgress,
}) => {
  const [statusFilter, setStatusFilter] = useState(statusSearch)
  const date = moment(currentDate)
  const prevMonth = moment(date).subtract(1, 'month')
  const nextMonth = moment(date).add(1, 'month')
  const hasProgress = Object.keys(progress).includes(
    DOWNLOAD_CSV_WORK_SCHEDULE_LIST
  )
  const isPreviousDisabled =
    date.isSameOrBefore(moment().subtract(1, 'years'), 'month') &&
    dateValidation({
      year: prevMonth.year(),
      month: prevMonth.month() + 1,
    })
  const isTwoMonthsBefore = !date.isSameOrBefore(
    moment()
      .subtract(2, 'months')
      .endOf('month')
  )

  const isNextDisabled =
    dateValidation({
      year: nextMonth.year(),
      month: nextMonth.month() + 1,
    }) && date.isSameOrAfter(moment().add(6, 'quarters'), 'month')
  const onDropdownChange = e => {
    history.push({
      search: queryString.stringify({
        clinicalDepartmentIds: e.value.map(({ id }) => id),
        statusFilter,
      }),
    })
  }
  const onSearch = () => {
    if (statusFilter) {
      history.push({
        search: queryString.stringify({
          clinicalDepartmentIds,
          statusFilter,
        }),
      })
    }
  }
  const removeSearch = () => {
    history.push({
      pathname: Router.get(Router.staffWorkSchedulesListWithoutYearMonth, {
        year: moment(currentDate).year(),
        month: moment(currentDate).month() + 1,
      }),
    })
  }
  const workScheduleStatus = workSchedule => {
    const { id, startTime, status, acceptedShiftId } = workSchedule
    const workDate = moment(startTime).startOf('day')
    if (status === i18n.t('main.completed')) {
      return <td style={{ color: '#797979' }}>{status}</td>
    }
    return (
      <td>
        <Link
          to={{
            search: `acceptedShiftId=${acceptedShiftId}`,
            pathname: `${Router.get(Router.staffDailyAvailableShifts, {
              year: workDate.year(),
              month: workDate.month() + 1,
              day: workDate.date(),
            })}${Router.get(Router.staffDetailWorkSchedule, {
              workScheduleId: id,
            })}`,
          }}
          style={{ color: '#f84e51', fontWeight: 'bold' }}
          rel='noopener noreferrer'
          target='_blank'
        >
          {status}
          <Button icon='pi pi-external-link' style={{ border: 'none' }} />
          {/* </div> */}
        </Link>
      </td>
    )
  }

  const statuses = [
    {
      value: '対応済み',
      label: '対応済み',
    },
    {
      value: '未対応',
      label: '未対応',
    },
  ]
  const workScheduleLink = workSchedule => {
    const { startTime, endTime } = workSchedule
    const workDate = moment(startTime).startOf('day')
    const workDateStartTime = moment(startTime).format('HH:mm')
    const workDateEndTime = moment(endTime).format('HH:mm')

    if (isTwoMonthsBefore && workSchedule.status !== i18n.t('main.completed')) {
      return (
        <td>
          {`${workDate.format(
            'YYYY年MM月DD日(ddd)'
          )} ${workDateStartTime} ~ ${workDateEndTime}`}
          <Link
            to={{
              pathname: Router.get(Router.staffDailyAvailableShifts, {
                year: workDate.year(),
                month: workDate.month() + 1,
                day: workDate.date(),
              }),
            }}
            rel='noopener noreferrer'
            target='_blank'
          >
            <Button icon='pi pi-external-link' style={{ border: 'none' }} />
          </Link>
        </td>
      )
    }
    return (
      <td>
        {`${workDate.format(
          'LL (dd)'
        )} ${workDateStartTime} ~ ${workDateEndTime}`}
      </td>
    )
  }
  useEffect(() => {
    if (socket && hasProgress) {
      socket.on(DOWNLOAD_CSV_WORK_SCHEDULE_LIST, data => {
        if (data?.percent) {
          setProgress({
            progress: DOWNLOAD_CSV_WORK_SCHEDULE_LIST,
            label: '応募シフトcsvダウンロード中',
            percent: Number(data?.percent),
          })
        }
      })
    }
  }, [socket, hasProgress])
  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('main.workScheduleList')}
          <BreadCrumb
            items={[
              {
                to: Router.get(Router.staffMonthlyAvailableShifts, {
                  year: date.year(),
                  month: date.month() + 1,
                }),
                title: moment(date).format('YYYY年MM月'),
              },
              { title: i18n.t('main.workScheduleList') },
            ]}
          />
        </div>
        <div
          className='staff-buttons'
          style={{
            marginTop: '50px',
            display: 'flex',
            justifyContent: 'end',
            width: '37%',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Button
              icon='pi pi-download'
              className='p-button-normal-available '
              label='応募シフトcsvダウンロード'
              onClick={async () => {
                setProgress({
                  progress: DOWNLOAD_CSV_WORK_SCHEDULE_LIST,
                  label: '応募シフトcsvダウンロード中',
                  percent: 0,
                })
                try {
                  const { status, data: urlS3 } = await Axios({
                    method: 'GET',
                    url: urlDownloadMonthlyWorkSchedules,
                  })
                  await waitFor(3000)
                  if (status === 204) {
                    setProgress({
                      progress: DOWNLOAD_CSV_WORK_SCHEDULE_LIST,
                      label: '応募シフトcsvダウンロード中',
                      msg: i18n.t('main.msgDownloadCSVStatusError'),
                    })
                    await waitFor(3000)
                  } else {
                    setProgress({
                      progress: DOWNLOAD_CSV_WORK_SCHEDULE_LIST,
                      percent: 100,
                      url: urlS3,
                    })
                  }
                } catch (err) {
                  setProgress({
                    progress: DOWNLOAD_CSV_WORK_SCHEDULE_LIST,
                    label: '応募シフトcsvダウンロード中',
                    msg: i18n.t('main.msgDownloadCSVStatusError'),
                  })
                  await waitFor(3000)
                }
                removeProgress(DOWNLOAD_CSV_WORK_SCHEDULE_LIST)
              }}
            />
          </div>
        </div>
      </div>
      <div className='container is-max'>
        <div className='box'>
          <div style={styles.header}>
            <Link
              to={Router.get(Router.staffWorkSchedulesList, {
                year: prevMonth.year(),
                month: prevMonth.month() + 1,
              })}
              onClick={isPreviousDisabled ? e => e.preventDefault() : undefined}
            >
              <Button icon='pi pi-chevron-left' disabled={isPreviousDisabled} />
            </Link>
            <div style={styles.title}>
              {moment(currentDate).format('YYYY年M月')}
            </div>
            <Link
              to={Router.get(Router.staffWorkSchedulesList, {
                year: nextMonth.year(),
                month: nextMonth.month() + 1,
              })}
              onClick={isNextDisabled ? e => e.preventDefault() : undefined}
            >
              <Button icon='pi pi-chevron-right' disabled={isNextDisabled} />
            </Link>
          </div>

          <div className='search-parent'>
            <MultiSelect
              value={clinicalDepartments.filter(({ id }) =>
                clinicalDepartmentIds.includes(id)
              )}
              fixedPlaceholder
              options={clinicalDepartments}
              optionLabel='clinicName'
              onChange={onDropdownChange}
              filter
              placeholder={i18n.t('main.chooseClinic')}
            />
            <Dropdown
              options={statuses}
              optionLabel='value'
              value={statusFilter}
              placeholder={i18n.t('main.statusSelect')}
              onChange={e => {
                setStatusFilter(e.value.value)
              }}
              style={{ marginLeft: '10px', marginRight: '1px' }}
              editable
            />
            <Button
              label={i18n.t('main.movingExpenses.search')}
              icon='pi pi-search'
              style={{ marginLeft: '20px' }}
              onClick={onSearch}
            />
            <Button
              icon='pi pi-times'
              onClick={() => {
                removeSearch()
              }}
              style={{
                marginLeft: '20px',
                marginRight: '1px',
                height: '35px',
              }}
            />
          </div>
          <Formik
            initialValues={{
              createdAt: false,
            }}
            render={formikProps => {
              const { setFieldValue, values } = formikProps
              return (
                <div className='overflow-container'>
                  <table className='table-workSchedule'>
                    <thead>
                      <tr>
                        <th width='13%'>
                          医師シフト応募日時
                          <Button
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                              color: '#686868',
                            }}
                            icon={
                              !values.createdAt
                                ? 'pi pi-sort-down'
                                : 'pi pi-sort-up'
                            }
                            onClick={() => {
                              if (!values.createdAt) {
                                setFieldValue('createdAt', true)
                              } else {
                                setFieldValue('createdAt', false)
                              }
                              onSortHandle({ sortField: 'createdAt' })
                            }}
                          />
                        </th>
                        <th width='13%'>シフト更新日時</th>
                        <th width='23%'>{i18n.t('main.clinicName')}</th>
                        <th width='7%'>{i18n.t('main.departmentName')}</th>
                        <th width='12%'>{i18n.t('main.fullname')}</th>
                        <th width='24%'>応募勤務日時</th>
                        <th width='10%'>対応状況</th>
                      </tr>
                    </thead>
                    <tbody>
                      {workScheduleLists.length > 0 ? (
                        workScheduleLists.map(ws => (
                          <tr key={ws.id}>
                            {ws.isCreatedByDoctor ||
                            ws.isCreatedByDoctor === null ? (
                              <>
                                <td>
                                  {moment(ws.createdAt).format(
                                    'YYYY/MM/DD HH:mm'
                                  )}
                                </td>
                                <td>
                                  {moment(ws.updatedAt).format(
                                    'YYYY/MM/DD HH:mm'
                                  )}
                                </td>
                                <td>{ws.clinicName}</td>
                                <td>{ws.clinicalDepartmentName}</td>
                                <td>{ws.fullName}</td>

                                {workScheduleLink(ws)}
                                {workScheduleStatus(ws)}
                              </>
                            ) : null}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7}>{i18n.t('main.noWorkSchedule')}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )
            }}
          />
          <Paginator
            rows={rowsPerPage}
            totalRecords={count}
            first={(page - 1) * rowsPerPage}
            onPageChange={onPageChange}
            leftContent={
              <div className='paginator-total'>
                {i18n.t('main.paginationTotal')}: {count}
              </div>
            }
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default WorkSchedulesListScene
