import ClinicNameRender from '@medical/components/ClinicNameRender'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import { splitLogicReverse } from '@medical/libs'
import ShiftFormWorkOutsideSplits from '@medical/pages/staff/components/ShiftFormWorkOutsideSplits'
import { Checkbox, Input } from 'antd'
import { ErrorMessage, Formik } from 'formik'
import { validateSDL } from 'graphql/validation/validate'
import moment from 'moment'
import { Button } from 'primereact/button'
import React from 'react'
import { isMobile } from 'react-device-detect'
import * as Yup from 'yup'

const AcceptedWorkScheduleOverTimeScene = ({
  i18n,
  workSchedule,
  departmentNames,
  onSubmit,
  closeModal,
  dataOvertime,
  workScheduleActualTime,
  date,
}) => {
  const { clinicalDepartment, startTime, endTime } = workSchedule

  const { TextArea } = Input
  const validationSchema = Yup.object()
    .shape({
      reasonOverTime: Yup.string().when('isOverTime', {
        is: true,
        then: Yup.string()
          .trim()
          .max(500, i18n.t('validation.title.max', { max: 500 }))
          .required('残業勤務理由を入力してください'),
      }),
      // startTime: Yup.string().when('isEarlyArrival', {
      //   is: true,
      //   then: Yup.string().test(
      //     'len',
      //     `${i18n.t('main.startTime')}${i18n.t('validation.required')}`,
      //     val => val !== undefined
      //   ),
      // }),
      reasonEarlyArrival: Yup.string().when('isEarlyArrival', {
        is: true,
        then: Yup.string()
          .trim()
          .max(500, i18n.t('validation.title.max', { max: 500 }))
          .required('早出勤務理由を入力してください'),
      }),
      comment: Yup.string().when('isComment', {
        is: true,
        then: Yup.string()
          .trim()
          .max(500, i18n.t('validation.title.max', { max: 500 }))
          .required('その他事務局へのコメントを入力してください'),
      }),
    })
    .test(
      'checkSelect',
      '早出または残業の理由を記載してから申請してください',
      value => {
        if (!value.isOverTime && !value.isEarlyArrival) {
          return false
        }
        return true
      }
    )
  return (
    <Formik
      enableReinitialize
      initialValues={{
        confirm: false,
        startTime: dataOvertime?.startTime || startTime,
        splits: dataOvertime
          ? splitLogicReverse({ availableShift: dataOvertime })
          : splitLogicReverse({ availableShift: workSchedule.acceptedShift }),
        isOverTime: dataOvertime ? dataOvertime.lateArrival : false,
        isEarlyArrival: dataOvertime ? dataOvertime.earlyDeparture : false,
        isShowOverTime: false,
        isShowArrival: false,
        // startTime: dataOvertime ? dataOvertime.startTime : '',
        endTime: dataOvertime ? dataOvertime.endTime : endTime,
        reasonEarlyArrival: dataOvertime
          ? dataOvertime.reasonEarlyDeparture
          : undefined,
        reasonOverTime: dataOvertime
          ? dataOvertime.reasonLateArrival
          : undefined,
        isDisableOverTime: dataOvertime ? dataOvertime.lateArrival : true,
        isDisableEarlyArrival: dataOvertime
          ? dataOvertime.earlyDeparture
          : true,
        comment: dataOvertime ? dataOvertime.comment : undefined,
        isComment: dataOvertime ? dataOvertime.comment : false,
        isDisableComment: dataOvertime ? dataOvertime.hasComment : true,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          setFieldValue,
          values,
          validateForm,
          setTouched,
          handleChange,
        } = formikProps
        if (isMobile) {
          return (
            <div className='' style={{ border: 'none' }}>
              <div className='schedule-calendar'>
                <div className='calendar-container'>
                  <div className='row' style={{ justifyContent: 'center' }}>
                    <div
                      className='move-expenses-header__title'
                      style={{ fontSize: '18px' }}
                    >
                      {i18n.t('doctor.overTime.title')}
                    </div>
                  </div>
                  <div className='tr'>
                    <div className='th'>{i18n.t('main.clinicName')}</div>
                    <div className='td'>
                      {ClinicNameRender({ clinicalDepartment })}
                    </div>
                  </div>
                  <div className='tr'>
                    <div className='th'> {i18n.t('main.departments')}</div>
                    <div className='td'>
                      {departmentNames[clinicalDepartment.name]}
                    </div>
                  </div>
                  <div className='tr'>
                    <div className='th'> {i18n.t('main.date')}</div>
                    <div className='td'>{moment(startTime).format('LL')}</div>
                  </div>
                  <div className='tr'>
                    <div className='th'>
                      {i18n.t('doctor.overTime.timeWork')}
                    </div>
                    {workScheduleActualTime && (
                      <div className='td'>
                        {` ${moment(workScheduleActualTime.startTime).format(
                          'HH:mm'
                        )}〜  ${moment(workScheduleActualTime.endTime).format(
                          'HH:mm'
                        )}`}
                      </div>
                    )}
                  </div>
                  <div className='row mt-15 move-inline move-expenses-item-container'>
                    <ShiftFormWorkOutsideSplits
                      {...formikProps}
                      i18n={i18n}
                      isDoctor
                      isMoneyPermitted
                      disabled={dataOvertime}
                    />
                  </div>

                  {values.isDisableEarlyArrival && (
                    <>
                      <div className=' mt-15 move-expenses-item-container'>
                        <div className='over-time-item__title checkbox'>
                          <Checkbox
                            checked={values.isEarlyArrival}
                            disabled={dataOvertime}
                            onChange={() => {
                              setFieldValue(
                                'isEarlyArrival',
                                !values.isEarlyArrival
                              )
                              setFieldValue(
                                'checked',
                                values.isEarlyArrival || values.isOverTime
                              )
                            }}
                          >
                            {i18n.t('doctor.overTime.earlyArrival')}
                          </Checkbox>
                        </div>
                      </div>
                      {values.isEarlyArrival && (
                        <>
                          <div className=' asterisk-label-head'>
                            早出勤務開始時刻を入力してください。患者様対応による早出勤務の場合には、電子カルテを開いた時刻を入力してください。患者様対応以外で早出勤務された場合には、早出勤務を開始した時刻を入力してください。
                          </div>
                          <div className=' tr'>
                            {/* <div className='th'>
                                {i18n.t('doctor.overTime.reasonEarlyArrival')}
                              </div> */}
                            <div className='td'>
                              <TextArea
                                autoSize
                                placeholder='早出勤務理由'
                                name='reasonEarlyArrival'
                                onChange={handleChange}
                                disabled={dataOvertime}
                                value={values.reasonEarlyArrival}
                              />
                              <ErrorMessage
                                name='reasonEarlyArrival'
                                component='div'
                                className='color-red'
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {values.isDisableOverTime && (
                    <div className=' mt-15  move-expenses-item-container'>
                      <div className='over-time-item__title checkbox'>
                        <Checkbox
                          checked={values.isOverTime}
                          disabled={dataOvertime}
                          onChange={() => {
                            setFieldValue('isOverTime', !values.isOverTime)
                            setFieldValue(
                              'checked',
                              values.isEarlyArrival || values.isOverTime
                            )
                          }}
                        >
                          {i18n.t('doctor.overTime.overTime')}
                        </Checkbox>
                      </div>
                    </div>
                  )}
                  {values.isOverTime && (
                    <>
                      <div className=' asterisk-label-head'>
                        残業勤務終了時刻を入力してください。患者様対応による残業の場合には、勤務時間の最後の患者様のカルテを閉じた時刻を入力してください。患者対応以外で残業した場合には、残業が終了した時刻を入力してください。
                        残業理由欄に、受付時間外に受付された患者様対応があった場合は、「時間外受付患者様対応
                        〇名」とお書きください。受付時間内に受付し、結果的に、診察自体がシフト時間外、受付時間外、開院時間外の場合には、「時間外受付患者様対応　〇名」の記載は必要ありません
                      </div>
                      <div className=' tr'>
                        {/* <div className='th'>
                          {i18n.t('doctor.overTime.reasonOverTime')}
                        </div> */}
                        <div className='td'>
                          <TextArea
                            autoSize
                            placeholder='残業勤務理由'
                            name='reasonOverTime'
                            onChange={handleChange}
                            disabled={dataOvertime}
                            value={values.reasonOverTime}
                          />
                          <ErrorMessage
                            name='reasonOverTime'
                            component='div'
                            className='color-red'
                          />
                        </div>
                        <span className='asterisk-label-head '>
                          確認用の情報であり、この記載が無くても受け入れ（時間外受付対応）インセンティブは支給されます。
                        </span>
                      </div>
                    </>
                  )}
                  <div className=' mt-15  move-expenses-item-container'>
                    <div className='over-time-item__title checkbox'>
                      <Checkbox
                        checked={values.isComment}
                        disabled={dataOvertime}
                        onChange={() => {
                          setFieldValue('isComment', !values.isComment)
                        }}
                      >
                        {i18n.t('doctor.overTime.comment')}
                      </Checkbox>
                    </div>
                  </div>
                  {values.isComment && (
                    <div className='tr'>
                      <div className='td'>
                        <TextArea
                          autoSize
                          placeholder='コメントを記入'
                          name='comment'
                          onChange={handleChange}
                          disabled={dataOvertime}
                          value={values.comment}
                        />
                        <ErrorMessage
                          name='comment'
                          component='div'
                          className='color-red'
                        />
                      </div>
                    </div>
                  )}
                  <div className='alert' style={{ textAlign: 'center' }}>
                    <ErrorMessage
                      name='undefined'
                      component='div'
                      className='color-red'
                    />
                  </div>
                  {!dataOvertime && (
                    <div
                      className='modal-bottom'
                      style={{ marginBottom: '5px' }}
                    >
                      <Button
                        onClick={closeModal}
                        type='submit'
                        className='outline-red'
                        label={i18n.t('main.deletedReasonCancel')}
                      />
                      <Button
                        className='reflect-button'
                        label={i18n.t('main.applyDoctor')}
                        disabled={!values.isEarlyArrival && !values.isOverTime}
                        type='submit'
                        onClick={() => {
                          validateForm().then(response => {
                            if (!Object.keys(response).length) {
                              setFieldValue('confirm', true)
                            } else {
                              setTouched(response)
                            }
                          })
                        }}
                      />
                    </div>
                  )}

                  <div className='confirmation-moving'>
                    <ConfirmationModal
                      {...formikProps}
                      confirmationMessage={i18n.t('doctor.overTime.submit')}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        }
        return (
          <div className='container move-expenses-container'>
            <div className='row' style={{ justifyContent: 'center' }}>
              <div
                className='move-expenses-header__title'
                style={{ fontSize: '18px' }}
              >
                {i18n.t('doctor.overTime.title')}
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='over-time-item__title'>
                {i18n.t('main.clinicName')}
              </div>
              <div className='width-67'>
                {ClinicNameRender({ clinicalDepartment })}
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='over-time-item__title'>
                {i18n.t('main.departments')}
              </div>
              <div className='width-67'>
                {departmentNames[clinicalDepartment.name]}
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='over-time-item__title'>{i18n.t('main.date')}</div>
              <div className='width-67'>{moment(startTime).format('LL')}</div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='over-time-item__title'>
                {i18n.t('doctor.overTime.timeWork')}
              </div>
              {workScheduleActualTime && (
                <div className='width-67 '>
                  {` ${moment(workScheduleActualTime.startTime).format(
                    'HH:mm'
                  )}〜
                  ${moment(workScheduleActualTime.endTime).format('HH:mm')}`}
                </div>
              )}
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <ShiftFormWorkOutsideSplits
                {...formikProps}
                i18n={i18n}
                isDoctor
                isMoneyPermitted
                isAcceptedWorkScheduleOverTime
                disabled={dataOvertime}
              />
            </div>
            {values.isDisableEarlyArrival && (
              <>
                <div className=' mt-15 move-expenses-item-container'>
                  <div className='over-time-item__title checkbox'>
                    <Checkbox
                      checked={values.isEarlyArrival}
                      disabled={dataOvertime}
                      onChange={() => {
                        setFieldValue('isEarlyArrival', !values.isEarlyArrival)
                        setFieldValue(
                          'checked',
                          values.isEarlyArrival || values.isOverTime
                        )
                      }}
                    >
                      {i18n.t('doctor.overTime.earlyArrival')}
                    </Checkbox>
                  </div>
                </div>
                {values.isEarlyArrival && (
                  <>
                    <div className=' asterisk-label-head'>
                      早出勤務開始時刻を入力してください。患者様対応による早出勤務の場合には、電子カルテを開いた時刻を入力してください。患者様対応以外で早出勤務された場合には、早出勤務を開始した時刻を入力してください。
                    </div>
                    <div className=' mt-15  move-expenses-item-container'>
                      {/* <div className='move-expenses-item__title overtime_fontsize'>
                          {i18n.t('doctor.overTime.reasonEarlyArrival')}
                        </div> */}
                      <div className='col-8'>
                        <TextArea
                          autoSize
                          placeholder='早出勤務理由'
                          name='reasonEarlyArrival'
                          onChange={handleChange}
                          disabled={dataOvertime}
                          value={values.reasonEarlyArrival}
                        />
                        <ErrorMessage
                          name='reasonEarlyArrival'
                          component='div'
                          className='color-red'
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {values.isDisableOverTime && (
              <div className=' mt-15  move-expenses-item-container'>
                <div className='over-time-item__title checkbox'>
                  <Checkbox
                    checked={values.isOverTime}
                    disabled={dataOvertime}
                    onChange={() => {
                      setFieldValue('isOverTime', !values.isOverTime)
                    }}
                  >
                    {i18n.t('doctor.overTime.overTime')}
                  </Checkbox>
                </div>
              </div>
            )}
            {values.isOverTime && (
              <>
                <div className=' asterisk-label-head'>
                  残業勤務終了時刻を入力してください。患者様対応による残業の場合には、勤務時間の最後の患者様のカルテを閉じた時刻を入力してください。患者対応以外で残業した場合には、残業が終了した時刻を入力してください。
                  残業理由欄に、受付時間外に受付された患者様対応があった場合は、「時間外受付患者様対応
                  〇名」とお書きください。受付時間内に受付し、結果的に、診察自体がシフト時間外、受付時間外、開院時間外の場合には、「時間外受付患者様対応　〇名」の記載は必要ありません
                </div>
                <div className=' mt-15  move-expenses-item-container'>
                  {/* <div className='move-expenses-item__title overtime_fontsize'>
                    {i18n.t('doctor.overTime.reasonOverTime')}
                  </div> */}
                  <div className='col-8'>
                    <TextArea
                      autoSize
                      placeholder='残業勤務理由'
                      name='reasonOverTime'
                      onChange={handleChange}
                      disabled={dataOvertime}
                      value={values.reasonOverTime}
                    />
                    <ErrorMessage
                      name='reasonOverTime'
                      component='div'
                      className='color-red'
                    />
                  </div>
                  <span className='asterisk-label-head '>
                    確認用の情報であり、この記載が無くても受け入れ（時間外受付対応）インセンティブは支給されます。
                  </span>
                </div>
              </>
            )}
            {values.isDisableComment && (
              <div className='mt-15 move-expenses-item-container'>
                <div className='over-time-item__title checkbox'>
                  <Checkbox
                    checked={values.isComment}
                    disabled={dataOvertime}
                    onChange={() => {
                      setFieldValue('isComment', !values.isComment)
                    }}
                  >
                    {i18n.t('doctor.overTime.comment')}
                  </Checkbox>
                </div>
              </div>
            )}
            {values.isComment && (
              <div className='mt-15 move-expenses-item-container'>
                <div className='col-8'>
                  <TextArea
                    autoSize
                    placeholder='コメントを記入'
                    name='comment'
                    onChange={handleChange}
                    disabled={dataOvertime}
                    value={values.comment}
                  />
                  <ErrorMessage
                    name='comment'
                    component='div'
                    className='color-red'
                  />
                </div>
              </div>
            )}
            <div className='alert'>
              <ErrorMessage
                name='undefined'
                component='div'
                className='color-red'
              />
            </div>
            {!dataOvertime && (
              <div className='modal-bottom' style={{ marginBottom: '5px' }}>
                <Button
                  onClick={closeModal}
                  type='submit'
                  className='outline-red'
                  label={i18n.t('main.deletedReasonCancel')}
                />
                <Button
                  className='reflect-button'
                  label={i18n.t('main.applyDoctor')}
                  // disabled={!values.isEarlyArrival && !values.isOverTime}
                  type='submit'
                  onClick={() => {
                    validateForm().then(response => {
                      if (!Object.keys(response).length) {
                        setFieldValue('confirm', true)
                      } else {
                        setTouched(response)
                      }
                    })
                  }}
                />
              </div>
            )}
            <div className='confirmation-moving'>
              <ConfirmationModal
                {...formikProps}
                confirmationMessage={i18n.t('doctor.overTime.submit')}
              />
            </div>
          </div>
        )
      }}
    />
  )
}
export default AcceptedWorkScheduleOverTimeScene
