import { DOCTOR_FRAGMENTS } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import {
  DEPARTMENTS_FRAGMENT,
  FILE_CATEGORIES_FRAGMENT,
  FILE_FRAGMENTS,
  GENDERS_FRAGMENT,
  JOIN_BACKGROUNDS_FRAGMENT,
  WORK_PATTERNS_FRAGMENT,
  INSURANCE_DOCTOR_FRAGMENT
} from '@medical/libs/general.graphql'
import { BANK_ACCOUNT_FRAGMENTS } from '@medical/pages/doctor/BankAccount/BankAccount.graphql'
import gql from 'graphql-tag'

export const DOCTOR_QUERY = gql`
  query DOCTOR_QUERY($doctorId: ID) {
    doctor(where: { id: $doctorId }) {
      ...DOCTOR_FRAGMENTS
      files(where: {deletedAt: null}) {
        ...FILE_FRAGMENTS
      }
      bankAccount {
        ...BANK_ACCOUNT_FRAGMENTS
      }
    }
    ...GENDERS_FRAGMENT
    ...DEPARTMENTS_FRAGMENT
    ...WORK_PATTERNS_FRAGMENT
    ...FILE_CATEGORIES_FRAGMENT
    ...JOIN_BACKGROUNDS_FRAGMENT
    ...INSURANCE_DOCTOR_FRAGMENT
  }
  ${DOCTOR_FRAGMENTS}
  ${FILE_CATEGORIES_FRAGMENT}
  ${GENDERS_FRAGMENT}
  ${DEPARTMENTS_FRAGMENT}
  ${WORK_PATTERNS_FRAGMENT}
  ${FILE_FRAGMENTS}
  ${BANK_ACCOUNT_FRAGMENTS}
  ${JOIN_BACKGROUNDS_FRAGMENT}
  ${INSURANCE_DOCTOR_FRAGMENT}
`
export const DOCTOR_WORK_TENDENCY_QUERY = gql`
  query DOCTOR_QUERY($doctorId: ID) {
    doctor(where: { id: $doctorId }) {
      id
      countedCancelWorkTendency {
        totalCount
        oneYear
        sixMonth
        threeMonth
        oneMonth
      }
      countedCancelLetterDueToClinicTendency {
        totalCount
        oneYear
        sixMonth
        threeMonth
        oneMonth
      }
    }
  }
`
export const QUERY_STAFF = gql`
  query STAFF_ME {
    staffMe {
      id
    }
  }
`

export const DOWNLOAD_FILE = gql`
  mutation DOWNLOAD_FILE($key: String!) {
    downloadFileS3(key: $key)
  }
`

export const STAFF_UPDATE_DOCTOR = gql`
  mutation STAFF_UPDATE_DOCTOR(
    $doctorId: ID!
    $lastname: String
    $lastnameKana: String
    $firstname: String
    $firstnameKana: String
    $birthday: DateTime
    $gender: Gender
    $medicalLicenseNumber: String
    $phoneNumber: String
    $stateOrRegion: String
    $address1: String
    $address2: String
    $zipCode: String
    $nearestStations: [String!]
    $placeOfWork: String
    $evaluationComment: String
    $workPattern: WorkPattern
    $adjustHourlyWageRange: Int
    $joinBackground: JoinBackground
    $joinBackgroundOther: String
    $departments: [DepartmentName!]
    $email: String!
    $graduationUniversity: String
    $graduationUniversityYear: Int
    $obtainDoctorLicenseYear: Int
    $professionalDescription: String
    $reasonKnowWebsites: [ReasonKnowWebsite!]
    $otherReasonKnowWebsite: String
    $isJoinedSublingualImmunotherapyELearning: Boolean
    $isJoinedOnlineDiagnosticTraining: Boolean
    $introducedBy: String
    $careerHistories: [CareerHistoryForUpsert!]
    $medicalRecord: String
    $orca: String
    $questionnaire: String
    $isAvailableOnMonday: Boolean
    $isAvailableOnTuesday: Boolean
    $isAvailableOnWednesday: Boolean
    $isAvailableOnThursday: Boolean
    $isAvailableOnFriday: Boolean
    $isAvailableOnSaturday: Boolean
    $isAvailableOnSunday: Boolean
    $isAvailableOnHoliday: Boolean
    $skillSet: [ID!]
    $bankName: String
    $bankCode: String
    $branchName: String
    $branchCode: String
    $accountType: String
    $accountNumber: String
    $accountHolderName: String
    $staffMemo: String
    $province: String
    $isCreateTransportationCost: Boolean
    $isUpdateAddress: Boolean
    $insuranceDoctorNumber: String
    $insuranceDoctorSymbol: String
    $insuranceDoctorType: InsuranceDoctorType
  ) {
    staffUpdateDoctor(
      doctorId: $doctorId
      lastname: $lastname
      lastnameKana: $lastnameKana
      firstname: $firstname
      firstnameKana: $firstnameKana
      birthday: $birthday
      gender: $gender
      medicalLicenseNumber: $medicalLicenseNumber
      stateOrRegion: $stateOrRegion
      address1: $address1
      address2: $address2
      zipCode: $zipCode
      nearestStations: $nearestStations
      placeOfWork: $placeOfWork
      evaluationComment: $evaluationComment
      workPattern: $workPattern
      adjustHourlyWageRange: $adjustHourlyWageRange
      joinBackground: $joinBackground
      joinBackgroundOther: $joinBackgroundOther
      departments: $departments
      phoneNumber: $phoneNumber
      email: $email
      graduationUniversity: $graduationUniversity
      graduationUniversityYear: $graduationUniversityYear
      obtainDoctorLicenseYear: $obtainDoctorLicenseYear
      professionalDescription: $professionalDescription
      reasonKnowWebsites: $reasonKnowWebsites
      otherReasonKnowWebsite: $otherReasonKnowWebsite
      isJoinedOnlineDiagnosticTraining: $isJoinedOnlineDiagnosticTraining
      isJoinedSublingualImmunotherapyELearning: $isJoinedSublingualImmunotherapyELearning
      introducedBy: $introducedBy
      careerHistories: $careerHistories
      medicalRecord: $medicalRecord
      orca: $orca
      questionnaire: $questionnaire
      isAvailableOnMonday: $isAvailableOnMonday
      isAvailableOnTuesday: $isAvailableOnTuesday
      isAvailableOnWednesday: $isAvailableOnWednesday
      isAvailableOnThursday: $isAvailableOnThursday
      isAvailableOnFriday: $isAvailableOnFriday
      isAvailableOnSaturday: $isAvailableOnSaturday
      isAvailableOnSunday: $isAvailableOnSunday
      isAvailableOnHoliday: $isAvailableOnHoliday
      skillSet: $skillSet
      bankName: $bankName
      bankCode: $bankCode
      branchName: $branchName
      branchCode: $branchCode
      accountType: $accountType
      accountNumber: $accountNumber
      accountHolderName: $accountHolderName
      staffMemo: $staffMemo
      province: $province
      isCreateTransportationCost: $isCreateTransportationCost
      isUpdateAddress: $isUpdateAddress
      insuranceDoctorType: $insuranceDoctorType
      insuranceDoctorNumber: $insuranceDoctorNumber
      insuranceDoctorSymbol: $insuranceDoctorSymbol
    ) {
      ...DOCTOR_FRAGMENTS
    }
  }
  ${DOCTOR_FRAGMENTS}
`

export const STAFF_UPSERT_DOCTOR_FILE = gql`
  mutation STAFF_UPSERT_DOCTOR_FILE(
    $doctorId: ID!
    $file: String!
    $fileCategory: FileCategory!
    $fileId: ID
    $filename: String!
    $mimetype: String!
  ) {
    staffUpsertDoctorFile(
      doctorId: $doctorId
      file: $file
      fileCategory: $fileCategory
      fileId: $fileId
      filename: $filename
      mimetype: $mimetype
    ) {
      ...DOCTOR_FRAGMENTS
      files {
        ...FILE_FRAGMENTS
      }
    }
  }
  ${DOCTOR_FRAGMENTS}
  ${FILE_FRAGMENTS}
`

export const STAFF_APPROVE_DOCTOR = gql`
  mutation STAFF_APPROVE_DOCTOR(
    $doctorId: String!
    $registrationStatus: String!
    $mailContent: String
    $isCreateAddressHistory: Boolean
    $isCreateTransportationCost: Boolean
  ) {
    staffApproveDoctor(
      doctorId: $doctorId
      registrationStatus: $registrationStatus
      mailContent: $mailContent
      isCreateAddressHistory: $isCreateAddressHistory
      isCreateTransportationCost: $isCreateTransportationCost
    ) {
      id
    }
  }
`
export const DOCTOR_RESEND_CONFIRMATION_EMAIL = gql`
  mutation DOCTOR_RESEND_CONFIRMATION_EMAIL($email: String!) {
    resendConfirmationEmail(email: $email) {
      id
    }
  }
`
export const DOCTOR_SKILLS = gql`
  query DoctorSkills($where: DoctorSkillWhereInput) {
    doctorSkills(where: $where) {
      id
      skill {
        id
        name
        isShow
        deletedAt
      }
      doctor {
        id
      }
    }
  }
`

export const STAFF_CREATE_DOCTOR_DELETE_REQUEST = gql`
  mutation STAFF_CREATE_DOCTOR_DELETE_REQUEST($doctorId: ID!, $staffId: ID) {
    staffCreateDoctorDeleteRequest(doctorId: $doctorId, staffId: $staffId) {
      id
    }
  }
`

export const STAFF_BLOCK_OR_UNBLOCK_DOCTOR = gql`
  mutation STAFF_BLOCK_OR_UNBLOCK_DOCTOR(
    $doctorId: ID!
    $isBlockedMail: Boolean!
  ) {
    staffBlockOrUnblockDoctorMail(
      doctorId: $doctorId
      isBlockedMail: $isBlockedMail
    )
  }
`

export const DELETE_DOCTOR_FILE = gql`
  mutation DELETE_DOCTOR_FILE(
    $fileId: ID!
  ) {
    staffDeleteDoctorFile(
      fileId: $fileId
    )
  }
`
