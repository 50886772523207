import ClinicNameRender from '@medical/components/ClinicNameRender'
import InputTextarea from '@medical/components/InputTextarea/index.js'
import numeralCurrency from '@medical/libs/numeralCurrency'
import { FieldArray, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { pathOr } from 'ramda'
import React from 'react'
import { isMobile } from 'react-device-detect'
import * as Yup from 'yup'

import DoctorCartConfirmation from './DoctorCartConfirmation'
import DoctorCartSp from './DoctorCartSp'
import styles from './Style'

const DoctorCartScene = ({
  i18n,
  cartItems,
  onSubmit,
  removeItemFromCart,
  departmentNames,
}) => {
  const validationSchema = Yup.object().shape({
    items: Yup.array().of(
      Yup.object().shape({
        doctorComment: Yup.string()
          .nullable()
          .max(300, i18n.t('validation.reply.max', { max: 300 })),
      })
    ),
  })
  return (
    <>
      <Formik
        initialValues={{
          confirm: false,
          items: cartItems,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        render={formikProps => {
          const {
            values,
            setFieldValue,
            validateForm,
            setTouched,
          } = formikProps

          if (isMobile) {
            return (
              <div className='container'>
                <div className='box'>
                  <div className='schedule-calendar'>
                    <div className='calendar-container'>
                      <FieldArray
                        name='items'
                        render={({ remove }) => (
                          <React.Fragment>
                            {values.items.length ? (
                              values.items.map((item, index) => (
                                <div style={styles.spCartContainer}>
                                  <div style={styles.removeButton}>
                                    <Button
                                      onClick={() => {
                                        remove(index)
                                        removeItemFromCart(item.id)
                                      }}
                                      className='p-button-danger'
                                      icon='pi pi-times'
                                    />
                                  </div>
                                  <DoctorCartSp
                                    workSchedule={item}
                                    key={item.id}
                                    departmentNames={departmentNames}
                                  />
                                  <InputTextarea
                                    {...formikProps}
                                    width={0}
                                    padding={0}
                                    fontSize={13}
                                    rows={2}
                                    value={pathOr(
                                      '',
                                      ['items', index, 'doctorComment'],
                                      values
                                    )}
                                    autoResize
                                    name={`items.${index}.doctorComment`}
                                  />
                                </div>
                              ))
                            ) : (
                              <div style={styles.spCartContainer}>
                                <td>{i18n.t('main.doctorCartNoItem')}</td>
                              </div>
                            )}
                          </React.Fragment>
                        )}
                      />
                      <div style={styles.bottom}>
                        <Button
                          onClick={() => {
                            values.items.forEach(item => {
                              setFieldValue('items', [])
                              removeItemFromCart(item.id)
                            })
                          }}
                          icon='pi pi-trash'
                          label={i18n.t('doctor.cart.clearAll')}
                          className='p-button-secondary'
                          style={styles.button}
                        />
                        <Button
                          onClick={() => {
                            validateForm().then(response => {
                              if (!Object.keys(response).length) {
                                setFieldValue('confirm', true)
                              } else {
                                setTouched(response)
                              }
                            })
                          }}
                          label={i18n.t('doctor.cart.submit')}
                          className='p-button-success'
                          style={styles.button}
                          disabled={!values.items.length}
                        />
                      </div>
                      <DoctorCartConfirmation
                        {...formikProps}
                        removeItemFromCart={removeItemFromCart}
                        confirmationMessage={i18n.t('doctor.cart.submitConfirmation')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          return (
            <div className='container'>
              <div className='box'>
                <div className='overflow-container'>
                  <table style={styles.table} className='table'>
                    <thead>
                      <tr>
                        <th width='30%'>{i18n.t('doctor.cart.clinicName')}</th>
                        <th width='15%'>{i18n.t('doctor.cart.date')}</th>
                        <th width='10%'>{i18n.t('doctor.cart.time')}</th>
                        <th width='10%'>{i18n.t('doctor.cart.amount')}</th>
                        <th width='30%'>{i18n.t('doctor.cart.comment')}</th>
                        <th width='5%'>{i18n.t('doctor.cart.remove')}</th>
                      </tr>
                    </thead>
                    <FieldArray
                      name='items'
                      render={({ remove }) => (
                        <tbody>
                          {values.items.length ? (
                            values.items.map((item, index) => (
                              <tr key={item.id}>
                                <td>
                                  {ClinicNameRender({
                                    clinicalDepartment: item.clinicalDepartment,
                                  })}
                                  &nbsp; (
                                  {
                                    departmentNames[
                                      item.clinicalDepartment.name
                                    ]
                                  }
                                  )
                                </td>
                                <td className='center'>
                                  {moment(item.startTime).format('LL (dd)')}
                                </td>
                                <td className='center'>
                                  {moment(item.startTime).format('HH:mm')}~
                                  {moment(item.endTime).format('HH:mm')}
                                </td>
                                <td>{numeralCurrency(item.dailySalary)}</td>
                                <td>
                                  <InputTextarea
                                    {...formikProps}
                                    width={0}
                                    padding={0}
                                    rows={2}
                                    value={pathOr(
                                      '',
                                      ['items', index, 'doctorComment'],
                                      values
                                    )}
                                    autoResize
                                    name={`items.${index}.doctorComment`}
                                  />
                                </td>
                                <td className='center'>
                                  <Button
                                    onClick={() => {
                                      remove(index)
                                      removeItemFromCart(item.id)
                                    }}
                                    className='p-button-danger'
                                    icon='pi pi-times'
                                  />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={6}>
                                {i18n.t('main.doctorCartNoItem')}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      )}
                    />
                  </table>
                </div>
                <div style={styles.bottom}>
                  <Button
                    onClick={() => {
                      values.items.forEach(item => {
                        setFieldValue('items', [])
                        removeItemFromCart(item.id)
                      })
                    }}
                    icon='pi pi-trash'
                    label={i18n.t('doctor.cart.clearAll')}
                    className='p-button-secondary'
                    style={styles.button}
                  />
                  <Button
                    onClick={() => {
                      validateForm().then(response => {
                        if (!Object.keys(response).length) {
                          setFieldValue('confirm', true)
                        } else {
                          setTouched(response)
                        }
                      })
                    }}
                    label={i18n.t('doctor.cart.submit')}
                    className='p-button-success'
                    style={styles.button}
                    disabled={!values.items.length}
                  />
                </div>
                <DoctorCartConfirmation
                  {...formikProps}
                  removeItemFromCart={removeItemFromCart}
                  confirmationMessage={i18n.t('doctor.cart.submitConfirmation')}
                />
              </div>
            </div>
          )
        }}
      />
    </>
  )
}

export default DoctorCartScene
