import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import ClinicNameRender from '@medical/components/ClinicNameRender'
import {
  clinicalDepartmentNames,
  combineNames,
  dateValidation,
  sortAndFilterByClinicOrder,
  onPageChange,
} from '@medical/libs'
import Auth from '@medical/middleware/auth'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import moment from 'moment'
import queryString from 'query-string'
import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import {
  removeProgress,
  setProgressBar,
} from '@medical/provider/store/reducers/progress/progress.action'
import { connect } from 'react-redux'
import { SocketContext } from '@medical/provider/socket'
import { WORKSCHEDULES_CONNECTION } from './WorkSchedulesList.graphql'
import WorkSchedulesListScene from './WorkSchedulesListScene'

const WorkSchedulesList = ({
  history,
  match: {
    path,
    params: { year = moment().year(), month = moment().month() },
  },
  location: { search },
  progress,
  setProgress,
  removeProgress,
}) => {
  const [{ i18n }] = useCustom()
  const socket = useContext(SocketContext)
  const [orderBy, setOrderBy] = useState('createdAt_DESC')
  const startDate = moment()
    .year(year)
    .month(month - 1)
    .startOf('month')
  const endDate = startDate.clone().add(1, 'month')
  const {
    clinicalDepartmentIds = [],
    statusFilter,
    page = 1,
    rowsPerPage = 100,
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const clinicalDepartmentIdsSearch =
    clinicalDepartmentIds && clinicalDepartmentIds.length > 0
      ? clinicalDepartmentIds
      : undefined
  const statusSearch = statusFilter
    ? statusFilter === '対応済み'
      ? true
      : false
    : undefined

  const variablesStatus = statusSearch
    ? { accepted: statusSearch }
    : { accepted: statusSearch }
  const variables = {
    first: first < 0 ? 10 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy: 'createdAt_DESC',
    where: {
      AND: [
        {
          startTime_gte: startDate,
          startTime_lt: endDate,
        },
      ],
      deletedAt: null,
      ClinicalDepartment: {
        id_in: clinicalDepartmentIdsSearch,
      },
      isCreatedByDoctor: true,
      ...variablesStatus,
    },
  }

  const { loading, error, data } = useQuery(WORKSCHEDULES_CONNECTION, {
    variables,
    fetchPolicy: 'network-only',
  })

  const compare = (a, b) =>
    moment(a.node.createdAt) > moment(b.node.createdAt) ? 1 : -1

  useEffect(() => {
    if (!loading && data && data.workSchedulesConnection) {
      if (orderBy === 'createdAt_ASC' || orderBy === 'createdAt_DESC') {
        data.workSchedulesConnection.edges.sort((a, b) =>
          orderBy === 'createdAt_ASC' ? compare(a, b) : compare(b, a)
        )
      }
    }
  }, [loading, data, orderBy])
  const momentDate = moment(`${year} ${month} 01`, 'YYYY MM DD')
  if (
    (dateValidation({ year, month }) &&
      momentDate.isBefore(moment().subtract(1, 'years'), 'month')) ||
    [
      Router.staffWorkSchedulesListWithoutMonth,
      Router.staffWorkSchedulesListWithoutYearMonth,
    ].includes(path)
  )
    return (
      <Redirect
        to={Router.get(Router.staffWorkSchedulesList, {
          year: moment().year(),
          month: moment().month() + 1,
        })}
      />
    )

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const onSortHandle = e => {
    if (e.sortField === 'createdAt') {
      setOrderBy(
        orderBy !== 'createdAt_ASC' ? 'createdAt_ASC' : 'createdAt_DESC'
      )
    }
  }
  const {
    workSchedulesConnection: { edges, aggregate },
    clinicalDepartments,
    departments,
  } = data
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })
  const departmentNames = clinicalDepartmentNames({ departments })
  const resultWorkScheduleLists = []
  edges.map(({ node }) => {
    resultWorkScheduleLists.push({
      id: node.id,
      createdAt: node.createdAt,
      updatedAt: node.updatedAt,
      startTime: node.startTime,
      endTime: node.endTime,
      clinicalDepartmentId: node.clinicalDepartment.id,
      clinicName: ClinicNameRender({
        clinicalDepartment: node.clinicalDepartment,
      }),
      clinicalDepartmentName: departmentNames[node.clinicalDepartment.name],
      fullName: `${node.doctor.lastname} ${node.doctor.firstname}`,
      status:
        node.accepted || node.deletedStatus
          ? `${i18n.t('main.completed')}`
          : `${i18n.t('main.notCompleted')}`,
      accepted: node.accepted,
      deletedStatus: node.deletedStatus,
      isCreatedByDoctor: node.isCreatedByDoctor,
      deletedAt: node.deletedAt,
      acceptedShiftId: node.acceptedShift?.id,
    })

    return resultWorkScheduleLists
  })
  const token = Auth.getToken()

  const urlDownloadMonthlyWorkSchedules = `${process.env.REACT_APP_ENDPOINT}/downloadMonthlyWorkSchedules?token=${token}&year=${year}&month=${month}`
  return (
    <WorkSchedulesListScene
      i18n={i18n}
      socket={socket}
      progress={progress}
      setProgress={progress => {
        setProgress(progress)
      }}
      removeProgress={progress => removeProgress(progress)}
      workScheduleLists={resultWorkScheduleLists}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortedClinicalDepartments,
        departmentNames,
      })}
      currentDate={startDate}
      clinicalDepartmentIds={clinicalDepartmentIds}
      onSortHandle={onSortHandle}
      rowsPerPage={rowsPerPage}
      page={page}
      count={aggregate.count}
      onPageChange={onPageChange(history, search)}
      history={history}
      statusSearch={statusFilter}
      urlDownloadMonthlyWorkSchedules={urlDownloadMonthlyWorkSchedules}
    />
  )
}
const mapStateToProps = state => ({
  progress: state.progressBarStore.progress,
})

const mapDispatchToProps = dispatch => ({
  setProgress: progress => dispatch(setProgressBar(progress)),
  removeProgress: progress => dispatch(removeProgress(progress)),
})
export default connect(mapStateToProps, mapDispatchToProps)(WorkSchedulesList)
