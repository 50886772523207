import { ADDRESS_FRAGMENTS } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const DOCTORS_QUERY = gql`
  fragment DOCTORS_QUERY on Query {
    doctors {
      id
      fullName
      fullNameKana
      email
      blocked
      workPattern
      isAvailableOnMonday
      isAvailableOnTuesday
      isAvailableOnWednesday
      isAvailableOnThursday
      isAvailableOnFriday
      isAvailableOnSaturday
      isAvailableOnSunday
      isAvailableOnHoliday
      registrationStatus
      firstWorkday
      nearestStations
      homeAddress {
        ...ADDRESS_FRAGMENTS
      }
    }
  }
  ${ADDRESS_FRAGMENTS}
`

export const DOCTOR_CONNECTION = gql`
  query DoctorsConnection(
    $where: DoctorWhereInput
    $orderBy: DoctorOrderByInput
    $first: Int
    $skip: Int
  ) {
    doctorsConnection(
      where: $where
      orderBy: $orderBy
      first: $first
      skip: $skip
    ) {
      edges {
        node {
          id
          fullName
          fullNameKana
          email
          blocked
          workPattern
          isAvailableOnMonday
          isAvailableOnTuesday
          isAvailableOnWednesday
          isAvailableOnThursday
          isAvailableOnFriday
          isAvailableOnSaturday
          isAvailableOnSunday
          isAvailableOnHoliday
          registrationStatus
          firstWorkday
          nearestStations
          homeAddress {
            ...ADDRESS_FRAGMENTS
          }
        }
      }
    }
  }
  ${ADDRESS_FRAGMENTS}
`
export const CLINICAL_DEPARTMENTS_QUERY_GET_FILTERED = gql`
  fragment CLINICAL_DEPARTMENTS_QUERY_GET_FILTERED on Query {
    clinicalDepartments(
      where: { deletedAt: null, Clinic: { deletedAt: null, isPublished: true } }
    ) {
      id
      name
      extraName
      departmentCode
      isDisplay
      clinic {
        id
        name
        order
        isPublished
      }
    }
  }
`
export const CLINICAL_DEPARTMENTS_QUERY = gql`
  fragment CLINICAL_DEPARTMENTS_QUERY on Query {
    clinicalDepartments {
      id
      name
      extraName
      departmentCode
      isDisplay

      clinic {
        id
        name
        order
        isPublished
      }
    }
  }
`

export const DOCTORS_DEPARTMENTS = gql`
  query DOCTORS_DEPARTMENTS {
    ...CLINICAL_DEPARTMENTS_QUERY
    ...DEPARTMENTS_FRAGMENT
  }
  ${CLINICAL_DEPARTMENTS_QUERY}
  ${DEPARTMENTS_FRAGMENT}
`
// export const DOCTOR_CONNECTION_DEPARTMENT = gql`
//   query DOCTOR_CONNECTION_DEPARTMENT {
//     ...CLINICAL_DEPARTMENTS_QUERY
//     ...DOCTOR_CONNECTION
//     ...DEPARTMENTS_FRAGMENT
//   }
//   ${CLINICAL_DEPARTMENTS_QUERY}
//   ${DOCTOR_CONNECTION}
//   ${DEPARTMENTS_FRAGMENT}
// `
export const DEPARTMENTS_LIST = gql`
  query DEPARTMENTS_LIST {
    ...CLINICAL_DEPARTMENTS_QUERY
    ...DEPARTMENTS_FRAGMENT
  }
  ${CLINICAL_DEPARTMENTS_QUERY}
  ${DEPARTMENTS_FRAGMENT}
`

export const STAFF_CREATE_AVAILABLE_SHIFT = gql`
  mutation STAFF_CREATE_AVAILABLE_SHIFT(
    $clinicalDepartmentId: ID!
    $startTime: DateTime!
    $endTime: DateTime!
    $hourlyWage: Int!
    $numberOfRooms: Int!
    $unpermittedDoctorIds: [ID!]!
    $splitDateTime1: DateTime
    $splitDateTime2: DateTime
    $splitDateTime3: DateTime
    $splitHourlyWage1: Int
    $splitHourlyWage2: Int
    $splitHourlyWage3: Int
    $comment: String
    $isPublished: Boolean!
    $isStartTimeBreakTime: Boolean
    $isSplitDateTime1BreakTime: Boolean
    $isSplitDateTime2BreakTime: Boolean
    $isSplitDateTime3BreakTime: Boolean
    $staffComments: [WorkScheduleCommentCreateInput!]
    $recruitmentCategory: [AvailableShiftRecruitmentCreateInput!]
    $isSpecial: Boolean
    $isEarlySpecial: Boolean    
    $isDoubleRecruitment: Boolean    
    $isSetIndividually: Boolean!
    $applyWith: IncentiveApplyType
    $workPatternApply: [String!]
    $listDoctorId: [ID!]
    $timeSettingHideShift: DateTime
  ) {
    createShift(
      clinicalDepartmentId: $clinicalDepartmentId
      startTime: $startTime
      endTime: $endTime
      hourlyWage: $hourlyWage
      numberOfRooms: $numberOfRooms
      unpermittedDoctorIds: $unpermittedDoctorIds
      splitDateTime1: $splitDateTime1
      splitDateTime2: $splitDateTime2
      splitDateTime3: $splitDateTime3
      splitHourlyWage1: $splitHourlyWage1
      splitHourlyWage2: $splitHourlyWage2
      splitHourlyWage3: $splitHourlyWage3
      comment: $comment
      isPublished: $isPublished
      isStartTimeBreakTime: $isStartTimeBreakTime
      isSplitDateTime1BreakTime: $isSplitDateTime1BreakTime
      isSplitDateTime2BreakTime: $isSplitDateTime2BreakTime
      isSplitDateTime3BreakTime: $isSplitDateTime3BreakTime
      staffComments: $staffComments
      recruitmentCategory: $recruitmentCategory
      isSpecial: $isSpecial
      isEarlySpecial: $isEarlySpecial                    
      isDoubleRecruitment: $isDoubleRecruitment      
      isSetIndividually: $isSetIndividually
      applyWith: $applyWith
      workPatternApply: $workPatternApply
      listDoctorId: $listDoctorId
      timeSettingHideShift: $timeSettingHideShift
    )
  }
`
export const CLINIC_TIME_SHEET = gql`
  query CLINIC_TIME_SHEET(
    $clinicalDepartmentId: ID
    $date: DateTime!
    $isHoliday: Boolean!
  ) {
    clinicTimeSheet(
      clinicalDepartmentId: $clinicalDepartmentId
      date: $date
      isHoliday: $isHoliday
    ) {
      clinicalDepartmentId
      startTimeMorning
      endTimeMorning
      isAvailableMorning
      startTimeAfternoon
      endTimeAfternoon
      isAvailableAfternoon
    }
  }
`
export const GET_SALARY_BY_TIME = gql`
  query getAvailableShiftDefaultHourlyWage(
    $clinicalDepartmentId: ID
    $date: DateTime!
    $isHoliday: Boolean!
  ) {
    availableShiftDefaultHourlyWage(
      clinicalDepartmentId: $clinicalDepartmentId
      date: $date
      isHoliday: $isHoliday
    )
  }
`
export const GET_SALARY_AVAILABLESHIFT = gql`
  query DefaultHourlyWageSchedules(
    $where: DefaultHourlyWageScheduleWhereInput
    $first: Int
    $skip: Int
  ) {
    defaultHourlyWageSchedules(where: $where, first: $first, skip: $skip) {
      id
      hourlyWageDefault
      isAvailableOnMonday
      isAvailableOnTuesday
      isAvailableOnWednesday
      isAvailableOnThursday
      isAvailableOnFriday
      isAvailableOnSaturday
      isAvailableOnSunday
      isAvailableOnHoliday
      startTime
      endTime
    }
  }
`
export const DEFAULT_HOURLY_WAGES = gql`
  query Query($where: DefaultHourlyWageWhereInput) {
    defaultHourlyWages(where: $where) {
      id
      workPattern
      hourlyWage
    }
  }
`

export const CREATE_MESSAGE = gql`
  mutation CREATE_MESSAGE(
    $message: String!
    $imageKey: String
    $conversationId: String
    $doctorId: String
  ) {
    createConversationContent(
      message: $message
      imageKey: $imageKey
      conversationId: $conversationId
      doctorId: $doctorId
    )
  }
`
