import { DOCTOR_FRAGMENTS } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const DOCTORS_CONNECTION = gql`
  query DOCTORS_CONNECTION(
    $where: DoctorWhereInput
    $first: Int
    $skip: Int
    $orderBy: DoctorOrderByInput
  ) {
    doctorsConnection(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      edges {
        node {
          ...DOCTOR_FRAGMENTS
          bankAccount {
            id
            createdAt
            updatedAt
            bankName
            bankCode
            branchName
            branchCode
            accountType
            accountNumber
            accountHolderName
          }
          registrationStatus
        }
      }
      aggregate(where: $where) {
        count
      }
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DOCTOR_FRAGMENTS}
  ${DEPARTMENTS_FRAGMENT}
`

export const DELETE_DOCTOR = gql`
  mutation DELETE_DOCTOR($doctorId: ID!) {
    staffDeleteDoctor(doctorId: $doctorId)
  }
`

export const BLOCK_DOCTOR = gql`
  mutation BLOCK_DOCTOR($doctorId: ID!) {
    staffBlockDoctor(doctorId: $doctorId) {
      id
    }
  }
`
export const RESTORE_BLOCKED_DOCTOR = gql`
  mutation RESTORE_BLOCKED_DOCTOR($doctorId: ID!) {
    staffRestoreBlockedDoctor(doctorId: $doctorId) {
      id
    }
  }
`
