import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import { CLINICAL_DEPARTMENTS_QUERY } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import gql from 'graphql-tag'

export const WORKSCHEDULES_CONNECTION = gql`
  query WORKSCHEDULES_CONNECTION(
    $where: WorkScheduleWhereInput
    $first: Int
    $skip: Int
    $orderBy: WorkScheduleOrderByInput
  ) {
    workSchedulesConnection(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      aggregate(where: $where) {
        count
      }
      edges {
        node {
          id
          startTime
          endTime
          accepted
          comment
          deletedReason
          acceptedReason
          deletedStatus
          acceptedAt
          isCreatedByDoctor
          acceptedShift {
            id
          }
          clinicalDepartment {
            id
            name
            extraName
            isDisplay
            clinic {
              id
              name
              nameKana
            }
          }
          updatedAt
          createdAt
          acceptedAt
          deletedAt
          doctor {
            id
            firstname
            lastname
          }
        }
      }
    }
    ...DEPARTMENTS_FRAGMENT
    ...CLINICAL_DEPARTMENTS_QUERY
  }
  ${CLINICAL_DEPARTMENTS_QUERY}
  ${DEPARTMENTS_FRAGMENT}
`
